import utils from 'utils/utils';
import PluginModel from '../model';
import Homad from './homad';

import iconPause from './icons/pause.svg';
import iconPlay from './icons/play.svg';
import iconSpeaker from './icons/speaker.svg';
import iconSpeakerMuted from './icons/speaker-muted.svg';
import iconLink from './icons/link.svg';
import iconFullscreen from './icons/fullscreen.svg';
import { createElement } from './dom';
import { homadAdTracker } from './tracking';

/**
 *
 * @param {number} duration duration in seconds
 */
function formatTime(duration) {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration - minutes * 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
}

class AABPlugin extends PluginModel {
    // eslint-disable-next-line class-methods-use-this
    getName() {
        return 'AABPlugin';
    }

    initialize() {
        this.elements = {};

        this.handleAdLabelClick = this.handleAdLabelClick.bind(this);
        this.handlePlayPauseClick = this.handlePlayPauseClick.bind(this);
        this.handleMuteClick = this.handleMuteClick.bind(this);
        this.handleFullscreenClick = this.handleFullscreenClick.bind(this);
    }

    setup() {
        // anti adblock works only for desktops
        if (utils.device.getDeviceType() !== 'desktop') {
            return;
        }

        this.player.once('ready', () => {
            const aab = this.player.config.get('aab');
            if (aab === true) {
                // force loading when mode is set to "true"
                this.initializeHomad();
            } else if (aab === 'auto') {
                // detect if adblock exist for "auto" mode
                if (this.player.model.getAdBlock()) {
                    this.initializeHomad();
                } else {
                    this.player.model.player.once('adBlock', () => {
                        this.initializeHomad();
                    });
                }
            }
        });
    }

    initializeHomad() {
        const pulse = this.player.getPlugin('PulseStats');
        this.homad = new Homad(this.player.model);
        homadAdTracker(this.player.model, this.homad, pulse);
        this.homad.once('initialize', this.onHomadReady, this);
    }

    /**
     * Initialize ad player
     */
    onHomadReady() {
        this.createControls(this.homad.container);

        this.hideHomadNativeControls();

        this.player.getContainer().classList.remove('jw-state-buffering');

        this.attachEventListeners();
        this.onMuteToggle({ type: this.homad.video.muted ? 'mute' : 'unmute' });

        const { setAdPlaying, setAdComplete } = this.getPlaybackState();
        this.player.model.once('adSlotStart', setAdPlaying);
        this.player.model.once('adSlotComplete', setAdComplete);
    }

    destroy() {
        if (this.homad) {
            this.homad.destroy();
        }
        this.detachEventListeners();
        super.destroy();
    }

    hideHomadNativeControls() {
        const { container } = this.homad;
        const [mainDiv] = [...container.childNodes].filter(
            (c) => c.childNodes.length > 0,
        );
        [...mainDiv.childNodes].forEach((element) => {
            if (element.tagName.toLowerCase() !== 'video') {
                element.style.cssText =
                    'display: none !important; z-index: unset !important';
            }
        });
    }

    /**
     * @param {HTMLDivElement} container Homad main element
     */
    createControls(container) {
        const muteButton = createElement('button', {});
        const playButton = createElement('button', {});
        const fullscreenButton = createElement('button', {});

        playButton.innerHTML = iconPause;
        fullscreenButton.innerHTML = iconFullscreen;

        const labelText = createElement('strong', {}, 'ANNONSE (00:00)');
        const iconPlaceholder = createElement('div', {});
        iconPlaceholder.innerHTML = iconLink;

        const label = createElement(
            'a',
            {
                href: '#',
                target: '_blank',
                rel: 'noopener',
            },
            [
                iconPlaceholder,
                createElement('div', { class: 'aab-labels' }, [
                    labelText,
                    createElement('span', {}, 'Gå til annonsør'),
                ]),
            ],
        );

        // Controlbar
        const controls = createElement(
            'div',
            {
                class: 'aab-layout',
            },
            [
                createElement('div', { class: 'aab-controls' }, [muteButton]),
                createElement('div', { class: 'aab-spacer' }),
                createElement('div', { class: 'aab-controls' }, [
                    playButton,
                    label,
                    createElement('div', {
                        class: 'aab-spacer',
                    }),
                    fullscreenButton,
                ]),
            ],
        );

        this.elements = {
            muteButton,
            playButton,
            fullscreenButton,
            labelText,
            label,
            controls,
        };

        // Append the whole controls' container to the DOM
        container.appendChild(controls);
    }

    attachEventListeners() {
        const { label, muteButton, fullscreenButton, playButton } =
            this.elements;

        label.addEventListener('click', this.handleAdLabelClick);
        muteButton.addEventListener('click', this.handleMuteClick);
        fullscreenButton.addEventListener('click', this.handleFullscreenClick);
        playButton.addEventListener('click', this.handlePlayPauseClick);

        this.listenTo(this.homad, 'adPlay', this.onAdPlay, this);
        this.listenTo(this.homad, 'adPause', this.onAdPause, this);
        this.listenTo(this.homad, 'adTime', this.onAdTime, this);
        this.listenTo(this.homad, 'mute unmute', this.onMuteToggle, this);
    }

    detachEventListeners() {
        try {
            const { label, muteButton, fullscreenButton, playButton } =
                this.elements;
            label.removeEventListener('click', this.handleAdLabelClick);
            muteButton.removeEventListener('click', this.handleMuteClick);
            fullscreenButton.removeEventListener(
                'click',
                this.handleFullscreenClick,
            );
            playButton.removeEventListener('click', this.handlePlayPauseClick);
        } catch (err) {
            // noop
        }
    }

    onAdPlay() {
        this.elements.playButton.innerHTML = iconPause;
    }

    onAdPause() {
        this.elements.playButton.innerHTML = iconPlay;
    }

    onAdTime() {
        const { duration, currentTime } = this.homad.video;
        const { labelText } = this.elements;
        labelText.innerText = `ANNONSE (${formatTime(duration - currentTime)})`;
    }

    handleAdLabelClick(event) {
        const { video } = this.homad;
        video.click();
        video.pause();
        event.preventDefault();
    }

    handlePlayPauseClick() {
        this.homad.playButton.click();
    }

    onMuteToggle({ type }) {
        const { muteButton } = this.elements;
        muteButton.innerHTML = type === 'mute' ? iconSpeakerMuted : iconSpeaker;
    }

    getPlaybackState() {
        const playerClassList = this.player.getContainer().classList;
        const adStarted = ['jw-flag-ads', 'svp-homad-playing'];

        const useClasses = (add, remove) => {
            playerClassList.add(...add);
            playerClassList.remove(...remove);
        };

        return {
            setAdPlaying: () => useClasses(adStarted, ['svp-homad-loading']),
            setAdComplete: () => useClasses([], adStarted),
        };
    }

    handleFullscreenClick() {
        try {
            const jwplayer = this.player.model.player;
            jwplayer.setFullscreen();
        } catch (e) {
            // fullscreen did not work
        }
    }

    handleMuteClick() {
        this.homad.muteButton.click();
    }
}

export default AABPlugin;
