/**
 * This utility function returns aspect ratio in the { width, height } format
 * If none of the predefined values are matched, it returns the neareast value
 */
export const getAspectRatioCompound = (
  aspectRatio: number,
  arSeries: [number, number][]
) => {
  let result = { width: 0, height: 0 };
  let diff = Infinity;
  arSeries
    // Include both portrait and landscape variants
    .flatMap((arr) => [arr, [...arr].reverse()])
    .forEach(([width, height]) => {
      const newDiff = Math.abs(aspectRatio - width / height);
      if (newDiff < diff) {
        diff = newDiff;
        result = { width, height };
      }
    });

  return result;
};
