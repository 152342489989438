import { ProviderAccessMap } from '@vgtv/svp-access/lib/access_mappings';
import { Provider } from '@vgtv/api-client';
import type {
  Asset,
  AssetAdditional,
  AssetImages,
  AssetStreamConfiguration,
  Nullable,
} from '@schibsted-svp/svp-api-types';
import type { CnpTagId } from '@vgtv/api-client/lib/cnp_tag';
import type { Player } from '@schibsted-svp/web-player';

import ServerTime from '../serverTime';
import { Unpacked } from '../../plugins/skin-vgtv2/utils/types';

import { getCategoryShortPreviewUrl } from './category';

type VideoPreview = {
  aspectRatio: string;
  height: number;
  url: string;
  width: number;
};

export const getTimestamp = (
  time?: Nullable<number>,
  useMs: boolean = false
) => {
  if (!time) {
    return undefined;
  }

  const isTimeInSeconds = time.toString().length <= 10;
  if (isTimeInSeconds) {
    return useMs ? time * 1000 : time;
  }
  return useMs ? time : Math.floor(time / 1000);
};

export const getAssetFlightTimesStart = (
  asset: {
    flightTimes?: {
      start?: Asset['flightTimes']['start'];
    };
  },
  useMs: boolean = false
): number | undefined => {
  const { flightTimes: { start } = {} } = asset;

  return getTimestamp(start, useMs) ?? undefined;
};

export const getAssetFlightTimesEnd = (
  asset: {
    flightTimes?: {
      end?: Asset['flightTimes']['end'];
    };
  },
  useMs: boolean = false
): number | undefined => {
  const { flightTimes: { end } = {} } = asset;

  return getTimestamp(end, useMs) ?? undefined;
};

export const getAssetAccess = <T extends Provider>(
  asset?: Asset
): ProviderAccessMap[T] | undefined => {
  return (
    Object.keys(asset?.additional?.access ?? {}) as ProviderAccessMap[T][]
  )[0];
};

export const hasStreamProperty = (
  asset: Asset | undefined,
  property: Unpacked<AssetStreamConfiguration['properties']>
) => asset?.streamConfiguration.properties.includes(property) ?? false;

export const isLiveStream = (asset: { streamType: Asset['streamType'] }) =>
  asset.streamType === 'live';

export const getStreamStartDate = (asset: Asset) => {
  const flightTimesStart = getAssetFlightTimesStart(asset, true);

  return new Date(flightTimesStart!);
};

export const getTagIds = (asset?: Asset) => {
  return (asset?.additional?.tags ?? []) as CnpTagId[];
};

export const getTimeToStart = async (asset: Asset) => {
  const serverTime = await ServerTime.get();

  return getStreamStartDate(asset).getTime() - serverTime;
};

type AssetLikeWithMetadata = {
  additional?: Nullable<{ metadata?: AssetAdditional['metadata'] }>;
};

const defaultAssetMetadata: AssetAdditional['metadata'] = {};
Object.freeze(defaultAssetMetadata);
export const getAssetMetadata = (asset?: AssetLikeWithMetadata) => {
  return asset?.additional?.metadata ?? defaultAssetMetadata;
};

/**
 * Returns a numerical aspect ratio value.
 */
const DEFAULT_ASPECT_RATIO = 16 / 9;
export const getAssetAspectRatio = (asset?: AssetLikeWithMetadata): number => {
  const metadata = getAssetMetadata(asset);

  return parseFloat(
    (parseFloat(metadata.aspectRatio) || DEFAULT_ASPECT_RATIO).toFixed(2)
  );
};

const parsePreviewsFromProperty = (str: string) => {
  if (str) {
    try {
      return JSON.parse(str) as VideoPreview[][];
    } catch {
      // noop
    }
  }
  return undefined;
};

export const getAssetPreviewUrl = (asset?: Asset) => {
  const {
    livepreview,
    preview_vivi_category: viviPreview,
    preview,
    preview_portrait: portraitStr,
  } = getAssetMetadata(asset);

  let previewUrl =
    viviPreview ||
    preview ||
    livepreview ||
    getCategoryShortPreviewUrl(asset?.category);

  if (asset?.streamType === 'live') {
    previewUrl = livepreview || previewUrl;
  }

  if (getAssetAspectRatio(asset) < 1) {
    const verticalPreviews = parsePreviewsFromProperty(portraitStr);
    previewUrl = verticalPreviews?.[0]?.[0]?.url || previewUrl;
  }

  return previewUrl;
};

const defaultTags: {
  id: CnpTagId;
  tag: string;
}[] = [];
Object.freeze(defaultTags);
export const getAssetEmbeddedTags = (asset: Asset) =>
  asset._embedded?.tags ?? defaultTags;

export const getAssetImage = <T extends keyof AssetImages>(
  asset: Asset,
  imageName: T
): AssetImages[T] => {
  let img = asset.images[imageName];
  if (img) {
    img = img.replace(/^http:/, 'https:');
  }
  return img;
};

export const getRawAsset = (player?: Player): Asset | undefined => {
  const asset = player?.getRawAsset();
  // Make sure we skip mock asset and not-yet-fetched assets
  // @ts-expect-error mock stream type does not exist in types
  if (asset && (!asset.title || asset.streamType === 'mock')) {
    return undefined;
  }

  return asset;
};
