const aabConfig = {
  enabled: true,
  server: [
    'https://ssl.1.damoh.vgtv.no/[hash]/',
    'https://ssl.2.damoh.vgtv.no/[hash]/',
    'https://ssl.3.damoh.vgtv.no/[hash]/',
  ],
  alias: 'novgtvibadnxscom',
  config: 'https://hgc-cf-cache-1.svonm.com/www.vgtv.no/config.json',
};

export const getAabConfigURL = () => {
  const blob = new Blob([JSON.stringify(aabConfig)], {
    type: 'application/json',
  });

  return URL.createObjectURL(blob);
};
