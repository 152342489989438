import { type ConsentManager, PERMISSION_VALUE } from '@vgtv/consent-manager';

import { resolveWithTimeout } from '../../utils/async';

const DEFAULT_TIMEOUT_IN_MILLISECONDS = 2500;

export const getAdPermission = async (consentManager?: ConsentManager) => {
  if (!consentManager) {
    return PERMISSION_VALUE.ACCEPTED;
  }

  const cmpConsent = await resolveWithTimeout({
    promise: consentManager.getConsentedToAll(),
    fallback: false,
    timeout: DEFAULT_TIMEOUT_IN_MILLISECONDS,
  });

  return cmpConsent ? '1' : '0';
};

export const getAdConsentKeywords = async (consentManager?: ConsentManager) => {
  const cmpConsent = await getAdPermission(consentManager);

  return {
    'aa-sch-cmp_advertising': cmpConsent,
    'cmp_advertising': cmpConsent,
  };
};
