import { homadDefaultConfig } from './config';
import { translateHomadEvents } from './eventbus';
import homadLib from './lib';

export function initializeHomadLib(aabConfig) {
    const SvpPlayer = (window.SVP && window.SVP.Player) || {};
    if (SvpPlayer.isHomadLoaded) {
        return;
    }

    SvpPlayer.isHomadLoaded = true;

    const homadConfig = { ...homadDefaultConfig };

    // override aab config url
    if (aabConfig) {
        homadConfig.clientConfig = aabConfig;
    }

    translateHomadEvents();
    homadLib(homadConfig);
}
