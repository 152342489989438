import utils from 'utils/utils';
import Model from 'utils/model';
import image from 'utils/image';
import { objectToString } from 'utils/string';
import ServerTime from 'utils/server-time';

import config from 'player/config';
import Domain from 'player/model/domain';
import Token from 'player/model/token';
import Asset from 'player/model/asset';
import Stream from 'player/model/stream';

import locale from 'player/model/locale';

import appnexus from './ads/appnexus';
import addPlaylistFactory from './config/playlist';
import { addSharing } from './config/sharing';
import setPlaybackOptions from './config/playback';
import addYoubora from './config/jw-youbora';
import setKey from './config/keys';
import setCaptions from './config/captions';
import { chromecastApps, fallbackChromecastApp } from './config/chromecast';
import { applyTtsOption } from './skin/tts-skin';
import { shouldUseAudioSkin } from './skin/audioSkinResolver';

import * as STATUS_CODES from './config/status-codes';
import { youboraSdk } from './youbora';

/**
 * @param {string} htmlError
 * @returns {string|undefined}
 * */
function getAkamaiReferenceId(htmlError) {
    return htmlError
        ?.split('Reference&#32;&#35;')
        ?.at(-1)
        ?.split('\n')
        ?.at(0)
        ?.replace(/&#46;/g, '.');
}
/**
 * Config instance for player
 * Contains SVP Player options
 * Parses config
 *
 * @constructor
 */
const Config = function () {
    this.attributes = {
        // DOM node
        node: null,
        // API vendor
        vendor: null,
        // player environment
        env: 'production',
        // stream object (extracted from asset)
        stream: null,
        // if not provided width will be read from node element
        width: '100%',
        // if not provided height will be read from node element
        height: '100%',
        // start playing from chapter time
        chapter: null,
        // start stream automatically on capable devices (does not work on iPhone and iPad, Android)
        autoplay: false,
        // player poster (image), mixed string (http src)|true|false
        poster: true,
        // display title on poster or change to provided string (default false)
        title: null,
        // play ahead time, format XXhYYmZZs, for example: 02h09m10s
        time: null,
        // repeat mode
        repeat: false,
        // minimum dvr window
        minDvrWindow: null,
        // url to css file with skin
        skin: config.skins.default,
        // right click defualt text and link
        about: {
            text: 'Stream',
            link: '',
        },
        /**
         * Token function for secured streams
         * @type {Token|null}
         */
        token: null,
        // disable default countdown plugin
        disableCountdownPlugin: false,
    };

    /**
     * JW Player Config
     */
    this.jwDefaults = {
        base: config.cdn.player,
        key: config.player.keys.default,
        preload: 'auto',
        horizontalVolumeSlider: true,
        doNotSaveCookies: true,
    };

    this.token = null;
};

Config.prototype = {
    /**
     * Calculates bitrate value based on given limit
     *
     * maxBitrate is increased by 15% due to VBR encoding
     * @returns {number}
     */
    getMaxBitrate() {
        return this.get('maxBitrate') * 1.15;
    },

    /**
     * @param {Stream} stream
     */
    setStream(stream) {
        this.stream = stream;

        this.token = new Token(
            stream.getId(),
            stream.get('access'),
            this.getMaxBitrate(),
        );
    },

    /**
     * Get config passed to SVP Player constructor
     * @returns {*|{}}
     */
    getRaw() {
        return { ...this.rawOptions } || {};
    },

    /**
     * @returns {boolean}
     */
    isPreviewMode() {
        const settings = this.getSettings();
        return settings.preview || false;
    },

    /**
     * Dump Javascript config to a string
     * @returns {string}
     */
    dump() {
        return objectToString(this.getRaw());
    },

    /**
     * TODO add tests
     *
     * @param {Stream} stream
     * @param {HTMLElement} playerContainer
     * @returns {string|null}
     */
    getPoster(stream, playerContainer) {
        // disable poster in TTS mode
        if (this.shouldApplyTextToSpeechSkin(stream)) {
            return null;
        }

        const container =
            playerContainer || document.getElementById(this.get('node'));

        const width = this.get('width');
        const height = this.get('height');

        if (utils.isString(this.get('poster'))) {
            return this.get('poster');
        }

        if (container && stream) {
            return image.getImageSrc(
                stream.getPoster(),
                'poster',
                // use width and height if it's set as a number
                utils.isNumber(width) ? width : container.clientWidth,
                utils.isNumber(height) ? height : container.clientHeight,
            );
        }

        return null;
    },

    /**
     * @param {Stream} [stream]
     * @returns {boolean}
     */
    shouldApplyTextToSpeechSkin(stream) {
        const ttsSkinOption = this.get('ttsSkin');
        if (typeof ttsSkinOption === 'boolean') {
            return ttsSkinOption;
        }

        const currentStream = stream || this.stream;
        return Boolean(currentStream) && currentStream.hasTextSpeech();
    },

    /**
     * @returns {string|undefined}
     */
    getVideoPreviewMetadataKey() {
        const { videoPreview } = this.getRaw();
        const isCustomMetadataKey = typeof videoPreview === 'string';

        const allPreviewsKeys = Object.keys(this.stream.getAllPreviewsUrls());

        if (!(videoPreview === true || isCustomMetadataKey)) {
            return undefined;
        }

        if (isCustomMetadataKey) {
            return videoPreview;
        }

        if (
            this.stream.isLive() &&
            allPreviewsKeys.includes(config.liveVideoPreview)
        ) {
            return config.liveVideoPreview;
        }

        return (
            config.videoPreview[this.get('vendor')] ||
            config.videoPreview.default
        );
    },

    /**
     * @param {Stream} stream
     * @param {HTMLElement} [playerContainer]
     * @returns {HTMLElement|undefined}
     */
    getVideoPreviewUrl(stream, playerContainer) {
        const container =
            playerContainer || document.getElementById(this.get('node'));
        if (!container || !stream || stream.isAudio()) {
            return undefined;
        }

        const metadataKey = this.getVideoPreviewMetadataKey(stream);

        return metadataKey ? stream.getVideoPreviewUrl(metadataKey) : undefined;
    },

    /**
     * @param {Stream} stream
     * @returns {string}
     */
    getTitle(stream) {
        const title = this.get('title');
        const isAudioWithoutCustomTitle =
            stream.isAudio() && !utils.isString(title);

        if (title === true || isAudioWithoutCustomTitle) {
            return stream.get('title');
        }

        return title;
    },

    /**
     * @param {Stream} stream
     * @returns {string|undefined}
     */
    getDescription(stream) {
        if (stream.isAudio()) {
            return stream.getCategory().getTitle();
        }

        return undefined;
    },

    /**
     * @param {Stream} stream
     * @returns {number|undefined}
     */
    getLiveSyncDuration(stream) {
        const chunkDuration = stream.getChunkDuration();
        if (chunkDuration) {
            // https://github.com/video-dev/hls.js/blob/master/docs/API.md#livesyncduration
            return chunkDuration * 2 + 1;
        }
        return undefined;
    },

    /**
     * Retrieve token required for secure streams
     * Token expiry has to match API
     *
     * @returns {Promise<string|null>}
     */
    getToken() {
        if (this.stream.isSecure()) {
            return this.token.fetch(
                this.get('token'),
                this.stream.hasAccess(),
                this.stream.getVendor(),
            );
        }

        return Promise.resolve(null);
    },

    /**
     * Token is required only for secure streams so for all other types it's valid
     *
     * @returns {boolean}
     */
    hasValidToken() {
        return !this.stream.isSecure() || this.token.isValid();
    },

    getStreamUrl(type) {
        // stream not set yet
        if (!this.stream) {
            // eslint-disable-next-line no-console
            console.error('SVP SDK: getStreamUrl() called before stream set');
            return null;
        }

        return this.getToken().then((token) => {
            const streamUrl = this.stream.getUrl(type);
            const maxBitrate = this.getMaxBitrate();
            const params = [];

            if (type === 'hls' && token) {
                params.push(`hdnea=${encodeURIComponent(token)}`);
            }

            if (maxBitrate) {
                params.push(`b=0-${maxBitrate}`);
            }

            return (
                streamUrl + (params.length > 0 ? `?${params.join('&')}` : '')
            );
        });
    },

    /**
     * Check if user is eligible to play stream in his geolocation
     *
     * @returns {Promise}
     */
    isStreamPlayable() {
        const { stream } = this;
        const settings = this.getSettings();

        return new Promise((resolve, reject) => {
            if (settings.preview === true) {
                resolve(STATUS_CODES.ACTIVE_PREVIEW);
            } else if (!ServerTime.fetched) {
                reject({ code: STATUS_CODES.NETWORK_ERROR });
            } else if (stream.isActive()) {
                if (stream.isGeoblocked() && !stream.isFuture()) {
                    this.getStreamUrl('hls')
                        .then(async (streamUrl) => {
                            const response = await fetch(streamUrl);

                            if (response.ok) return resolve();

                            const htmlError = await response.text?.();
                            const referenceId = getAkamaiReferenceId(htmlError);

                            if (response.status === 403) {
                                return reject({
                                    code: STATUS_CODES.NOT_ACTIVE_GEOBLOCKED,
                                    referenceId,
                                });
                            }
                            if (response.status === 401) {
                                return reject({
                                    code: STATUS_CODES.NOT_ACTIVE_TOKEN,
                                    referenceId,
                                });
                            }
                            if (response.status === 404) {
                                return reject({
                                    code: STATUS_CODES.NOT_FOUND,
                                    referenceId,
                                });
                            }
                            return reject({
                                code: STATUS_CODES.NETWORK_ERROR,
                                referenceId,
                            });
                        })
                        .catch(() => {
                            reject({ code: STATUS_CODES.NETWORK_ERROR });
                        });
                } else {
                    resolve(STATUS_CODES.ACTIVE);
                }
            } else if (stream.isPast()) {
                reject({ code: STATUS_CODES.NOT_ACTIVE_PAST });
            } else {
                reject({ code: STATUS_CODES.NOT_ACTIVE });
            }
        });
    },

    /**
     * @param {Stream} stream
     * @returns {Stream[]}
     */
    getPlaylistItems(stream) {
        if (!stream) {
            return [];
        }
        const vendor = this.get('vendor');
        const playlistStreams = stream
            .get('playlist')
            .map((data) => {
                const asset = new Asset(data);
                asset.set('vendor', vendor);
                return asset;
            })
            .map((asset) => new Stream(asset.attributes));
        return [stream].concat(playlistStreams);
    },

    getChromecastAppId() {
        const vendorEntry = Object.entries(chromecastApps).find((entry) =>
            entry[1].includes(this.stream.getVendor()),
        );
        return (vendorEntry && vendorEntry[0]) || fallbackChromecastApp;
    },

    getJwConfig(stream) {
        const settings = this.getSettings();

        return (
            Promise.resolve({
                width: this.get('width'),
                height: this.get('height'),
                abouttext: this.get('about').text,
                aboutlink: this.get('about').link,
                floating: this.get('floating'),
                sharing: utils.extend({}, config.sharing.global),
                localization: locale.translate('player', true),
                plugins: {},
                cast: {
                    customAppId: this.getChromecastAppId(),
                },
                playlist: [],
                ...(this.getLiveSyncDuration(stream)
                    ? { liveSyncDuration: this.getLiveSyncDuration(stream) }
                    : {}),
            })
                .then((jwConfig) => {
                    const playlistItems = this.getPlaylistItems(stream);
                    const addPlaylistItemsPromise = playlistItems.reduce(
                        (promise, currentStream) => {
                            const streamUrlPromise = promise.then(() => {
                                // change to the current stream object to prepare a valid stream url
                                this.setStream(currentStream);
                                return this.getStreamUrl('hls');
                            });

                            return streamUrlPromise.then((streamUrl) => {
                                const playlistSettings =
                                    this.getPlaylistSettings();
                                const playlistOptions = {
                                    poster: this.getPoster(currentStream),
                                    preview:
                                        this.getVideoPreviewUrl(currentStream),
                                    title: this.getTitle(currentStream),
                                    description:
                                        this.getDescription(currentStream),
                                    locale: locale,
                                    minDvrWindow: this.get('minDvrWindow'),
                                };

                                const applyPlaylistAdding = addPlaylistFactory(
                                    streamUrl,
                                    currentStream,
                                    playlistOptions,
                                    playlistSettings,
                                    {
                                        forceImaClientLoading: this.get(
                                            'forceImaClientLoading',
                                        ),
                                        shouldUseAudioSkin:
                                            shouldUseAudioSkin(this),
                                    },
                                );

                                return applyPlaylistAdding(jwConfig);
                            });
                        },
                        Promise.resolve(),
                    );

                    return addPlaylistItemsPromise.then(() => {
                        // revert to the origin stream object
                        this.setStream(stream);
                        return jwConfig;
                    });
                })
                .then(
                    addSharing({
                        options: this.get('sharing'),
                        stream,
                    }),
                )
                .then((jwConfig) => applyTtsOption(jwConfig, stream, this))
                .then(setCaptions(this.get('captions')))
                // setPlaybackOptions has to be invoked in the closure to inject the current values of mute, skin, etc.
                // otherwise it will get values when getJwConfig was called
                .then((jwConfig) =>
                    setPlaybackOptions(stream, {
                        mute: this.get('mute'),
                        skin: this.get('skin'),
                        autoplay: this.get('autoplay'),
                        autopause: this.get('autopause'),
                        repeat: this.get('repeat'),
                    })(jwConfig),
                )
                .then(addYoubora(stream, youboraSdk.state === 'READY'))
                .then(setKey(this.get('vendor')))
                .then((jwConfig) =>
                    utils.merge(
                        utils.extend({}, this.jwDefaults, jwConfig),
                        settings.jw || {},
                    ),
                )
        );
    },

    /**
     * Check if recommended is available
     */
    hasRecommended() {
        // recommended truned on and repeat truned off
        return (
            this.get('recommended') !== false && this.get('repeat') === false
        );
    },

    getRecommended() {
        return this.get('recommended');
    },

    getAgeLimit() {
        return this.get('ageLimit');
    },

    hasNext() {
        if (!this.hasRecommended()) {
            return false;
        }

        if (
            this.getRecommended() &&
            (this.getRecommended().next === false ||
                typeof this.getRecommended().next === 'undefined')
        ) {
            return false;
        }

        return true;
    },

    getPlaylistSettings() {
        return utils.extend(
            {
                adn: this.get('adn'),
                hasNext: this.hasNext(),
            },
            this.getSettings(),
        );
    },

    getLiveMidrollTag(time) {
        return appnexus.getLiveMidrollTag(
            time,
            this.stream,
            this.getPlaylistSettings(),
        );
    },

    parse(options) {
        const asset = options.asset || options.id;
        const about = config.about[options.vendor];
        const skin = utils.extend(
            {},
            config.skins[options.vendor] || config.skins.default,
        );

        // set default skin for player if nothing is provided
        if (typeof options.skin === 'object') {
            if (options.skin.name) {
                skin.name += ` ${options.skin.name}`;
            }

            if (options.skin.url) {
                skin.url = options.skin.url;
            }
        } else {
            // remove if not valid
            delete options.skin;
        }

        options.skin = skin;

        // override about link/text
        if (!options.about && about) {
            options.about = about;
        }

        options.floating = {
            dismissible: options.floating?.dismissible ?? true,
            mode: options.floating?.mode ?? 'never',
        };

        // delete asset
        if (asset) {
            delete options.asset;
        }

        // check if user passed chapter or time
        if (utils.isString(options.chapter) && options.chapter.match(/^\d+$/)) {
            options.chapter = parseInt(options.chapter, 10);
        }

        // legacy support
        if (options.time) {
            options.time = utils.time.shareTimeToSeconds(options.time);
        }

        if (options.settings) {
            delete options.settings;
        }

        return options;
    },
};

Object.defineProperty(Config.prototype, 'initialize', {
    value(options) {
        const { vendor } = options;
        const rawOptions = utils.extend({}, options);

        this.rawOptions = rawOptions;

        Domain.getPermissions(vendor).then((permissions) => {
            const settings = {};

            if (permissions.whitelist === true && options.settings) {
                utils.extend(settings, options.settings);
            } else if (
                permissions.whitelist === false &&
                options.settings &&
                options.settings.jw
            ) {
                settings.jw = {};
                utils.extend(settings.jw, options.settings.jw);
            }

            if (permissions.preview === false) {
                delete settings.preview;
            }

            // turn off ads for certain provider
            if (config.ads[vendor] === false) {
                settings.na = true;
            }

            Object.defineProperty(this, 'settings', {
                value: Object.freeze(settings),
                writable: false,
            });

            utils.extend(this.attributes, this.parse(options));
            this.trigger('ready', rawOptions, settings);
        });
    },
    writable: false,
});

// Immutable method for restricted settings like preview or na
Object.defineProperty(Config.prototype, 'getSettings', {
    value() {
        return this.settings;
    },
    writable: false,
});

utils.extend(Config.prototype, Model);

export default Config;
